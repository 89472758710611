<template>
  <div class="app-page-toolbar">
<!--    <router-link-->
<!--      :to="{ path: '/iam/new' }"-->
<!--      v-if="hasPermissionToCreate"-->
<!--    >-->
<!--      <el-button icon="el-icon-fa-plus" type="primary" :class="rtlAndPadding">-->
<!--        <span class="card-title"> <app-i18n code="common.new"></app-i18n> </span>-->
<!--      </el-button>-->
<!--    </router-link>-->

    <!--    <router-link-->
    <!--      :to="{ path: '/iam/import' }"-->
    <!--      v-if="hasPermissionToImport"-->
    <!--    >-->
    <!--      <el-button icon="el-icon-fa-upload" type="primary">-->
    <!--        <app-i18n code="common.import"></app-i18n>-->
    <!--      </el-button>-->
    <!--    </router-link>-->

    <el-tooltip
      :content="buttonsTooltip"
      :disabled="!buttonsTooltip"
      v-if="hasPermissionToEdit"
    >
      <span>
        <el-button
          :disabled="buttonsDisabled"
          @click="doRemoveAllSelected()"
          icon="el-icon-fa-times"
          type="primary"
          :class="rtlAndPadding"
        >
         <span class="card-title"> <app-i18n code="user.items.removeRoles"></app-i18n> </span>
        </el-button>
      </span>
    </el-tooltip>

    <el-tooltip
      :content="buttonsTooltip"
      :disabled="!buttonsTooltip"
      v-if="hasPermissionToEdit"
    >
      <span>
        <el-button
          :disabled="buttonsDisabled"
          @click="doEnableAllSelected()"
          icon="el-icon-fa-check"
          type="primary"
          :class="rtlAndPadding"
        >
          <span class="card-title" > <app-i18n code="iam.enable"></app-i18n> </span>
        </el-button>
      </span>
    </el-tooltip>

    <el-tooltip
      :content="buttonsTooltip"
      :disabled="!buttonsTooltip"
      v-if="hasPermissionToEdit"
    >
      <span>
        <el-button
          :disabled="buttonsDisabled"
          @click="doDisableAllSelected()"
          icon="el-icon-fa-ban"
          type="primary"
          :class="rtlAndPadding"
        >
          <span class="card-title"> <app-i18n code="iam.disable"></app-i18n> </span>
        </el-button>
      </span>
    </el-tooltip>

      <el-tooltip
              :content="buttonsTooltip"
              :disabled="!buttonsTooltip"
              v-if="hasPermissionToEdit"
      >
      <span>
        <el-button
                :disabled="buttonsDisabled"
                @click="doDestroyAllSelected()"
                icon="el-icon-fa-trash"
                type="primary"
                :class="rtlAndPadding"
        >
          <span class="card-title"> <app-i18n code="user.items.permanentDelete"></app-i18n> </span>
        </el-button>
      </span>
      </el-tooltip>

    <!-- <router-link
      :to="{
        path: '/audit-logs',
        query: { entityNames: 'user' },
      }"
      v-if="hasPermissionToAuditLogs"
    >
      <el-button icon="el-icon-fa-history">
        <app-i18n code="auditLog.menu"></app-i18n>
      </el-button>
    </router-link> -->

   <!-- <el-tooltip
      :content="exportButtonTooltip"
      :disabled="!exportButtonTooltip"
    >
      <span>
        <el-button
          :disabled="exportButtonDisabled"
          @click="doExport()"
          icon="el-icon-fa-file-excel-o"
          :class="rtlAndPadding"
        >
          <span class="card-title"> <app-i18n code="common.export"></app-i18n> </span>
        </el-button>
      </span>
    </el-tooltip>-->
  </div>
</template>

<script>
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters, mapActions } from 'vuex';
import { IamPermissions } from '@/modules/iam/iam-permissions';
import { i18n } from '@/i18n';

export default {
  name: 'app-iam-list-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasRows: 'iam/list/hasRows',
      loading: 'iam/list/loading',
      exportLoading: 'iam/list/exportLoading',
      selectedRows: 'iam/list/selectedRows',
      rtlAndPadding: 'iam/form/rtlAndPadding',
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    hasPermissionToCreate() {
      return new IamPermissions(this.currentUser).create;
    },

    hasPermissionToEdit() {
      return new IamPermissions(this.currentUser).edit;
    },

    hasPermissionToImport() {
      return new IamPermissions(this.currentUser).import;
    },

    exportButtonDisabled() {
      return (
        !this.hasRows || this.loading || this.exportLoading
      );
    },

    exportButtonTooltip() {
      return !this.hasRows
        ? i18n('common.noDataToExport')
        : null;
    },

    buttonsTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    buttonsDisabled() {
      return !this.selectedRows.length || this.loading;
    },

  },

  methods: {
    ...mapActions({
      doExport: 'iam/list/doExport',
      doRemoveAllSelected: 'iam/list/doRemoveAllSelected',
      doDisableAllSelected: 'iam/list/doDisableAllSelected',
      doEnableAllSelected: 'iam/list/doEnableAllSelected',
      _doDestroyAllSelected: 'iam/list/doDestroyAllSelected',
    }),
    async doDestroyAllSelected(){
        try {
            await this.$confirm(
                i18n('common.areYouSure'),
                i18n('common.confirm'),
                {
                    confirmButtonText: i18n('common.yes'),
                    cancelButtonText: i18n('common.no'),
                    type: 'warning',
                },
            );
            this._doDestroyAllSelected()
        }catch (e) {
            console.log("Destroy Error: " + e.message)
        }
    }
  },
};
</script>

<style></style>
